<template>
    <div>
        <div v-if="loading" class="text-center mt-5">
            {{ $t('globals.loading') }}
        </div>
    </div>
</template>

<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    name: 'EmailLogin',
    data: vm => ({
        loading: false,
        email  : '',
    }),
    mixins: [ HasErrorHandlerMixin ],
    created() {
        this.confirmEmail()
    },
    methods: {
        async confirmEmail() {
            this.loading = true

            let data   = this.$route.query
            this.email = data.email

            let res = await this.$axios.post('/customer/confirm-email', data)
                .catch(this.preErrorHandler)

            if (res && res.status == 204) {
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t('views.EmailConfirmationView.alerts.confirmation_success', { email: this.email }), 'success', resolve, true)
                })
            }

            this.loading = false
            this.handleClosed()
        },
        async preErrorHandler(error) {
            let errorMessage = this.$lodash.get(error, 'response.data.message')
            if (errorMessage == 'already_verified') {
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t('views.EmailConfirmationView.alerts.already_confirmed', { email: this.email }), 'success', resolve, true)
                })
                this.handleClosed()
                return
            }

            await new Promise((resolve) => {
                this.$bus.$emit('alert', this.$t('views.EmailConfirmationView.alerts.confirmation_error', { email: this.email }), 'error', resolve, true)
            })

            this.errorHandler(error)
            this.handleClosed()
        },
        handleClosed() {
            this.$router.push({ name: 'home' })
        },
    },
}
</script>