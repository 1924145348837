<template>
    <v-form @submit="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" xs="12" :sm="colSizeNameField()" class="py-0">
                <cp-text-field
                    ref="name_field"
                    :label="$t('forms.CompleteForm.name.label')"
                    :placeholder="$t('forms.CompleteForm.name.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('name')"
                    v-model="value.name"
                    @input="clearErrorMessages('name')"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="6" class="py-0">
                <cp-document-field
                    ref="document_field"
                    :label="$t(`forms.CompleteForm.document.label.${computedUseCNPJ ? 'cnpj' : 'cpf'}`)"
                    :placeholder="$t(`forms.CompleteForm.document.placeholder.${computedUseCNPJ ? 'cnpj' : 'cpf'}`)"
                    required type="text"
                    :useCNPJ.sync="computedUseCNPJ"
                    :error-messages="getErrorMessages('document')"
                    v-model="value.document"
                    @input="clearErrorMessages('document')"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="6" class="py-0">
                <cp-text-field
                    ref="email_field"
                    :label="$t('forms.CompleteForm.email.label')"
                    :placeholder="$t('forms.CompleteForm.email.placeholder')"
                    :hint="!isEmailLogin ? $t('forms.CompleteForm.email.hint') : ''"
                    persistent-hint
                    required type="text"
                    :error-messages="getEmailErrorMessages()"
                    v-model="value.email"
                    @input="clearErrorMessages('email')"
                    :disabled="!isEmailLogin"
                />
            </v-col>

            <v-col v-if="shouldShowField('phone')" 
                cols="12" xs="12" class="py-0"
                :sm="6"
                >
                <cp-phone-field
                    ref="phone_field"
                    :label="$t('forms.CompleteForm.phone.label')"
                    :placeholder="$t('forms.CompleteForm.phone.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('phone')"
                    v-model="value.phone"
                    @input="clearErrorMessages('phone')"
                    @keyup.enter="$emit('triggerSubmit')"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="6" class="py-0">
                <cp-date-field
                    ref="birthdate_field"
                    :label="$t('forms.CompleteForm.birthdate.label')"
                    :placeholder="$t('forms.CompleteForm.birthdate.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('birthdate')"
                    v-model="value.birthdate"
                    @input="clearErrorMessages('birthdate')"
                    @keyup.enter="submit"
                    reactive birthday
                    outputFormat="DD-MM-YYYY"
                />
            </v-col>

            <v-col v-if="shouldShowField('sex')" 
                cols="12" xs="12" class="py-0"
                :sm="6"
            >
                <GenderSelectInput
                    ref="sex_field"
                    :label="$t('forms.CompleteForm.gender.label')"
                    :placeholder="$t('forms.CompleteForm.gender.placeholder')"
                    :hint="$t('forms.CompleteForm.gender.hint')"
                    v-model="value.sex"
                    :error-messages="getErrorMessages('sex_field')"
                    @input="clearErrorMessages('sex_field')"
                />
            </v-col>

            <v-row v-if="value.require_password" class="py-0 mx-0">
                <v-col cols="12" xs="12" sm="6" class="py-0">
                    <cp-password-field
                        ref="password_field"
                        :label="$t('forms.CompleteForm.password.label_required')"
                        :hint="$t('forms.CompleteForm.password.hint')"
                        :placeholder="$t('forms.CompleteForm.password.placeholder')"
                        type="text"
                        :error-messages="getPasswordErrorMessages()"
                        v-model="value.password"
                        @input="clearErrorMessages('password')"
                    />
                </v-col>

                <v-col cols="12" xs="12" sm="6" class="py-0">
                    <cp-password-field
                        ref="password_confirmation_field"
                        :label="$t('forms.CompleteForm.password_confirmation.label_required')"
                        :hint="$t('forms.CompleteForm.password_confirmation.hint')"
                        :placeholder="$t('forms.CompleteForm.password_confirmation.placeholder')"
                        type="text"
                        :error-messages="getConfirmationPasswordErrorMessages()"
                        v-model="value.password_confirmation"
                        @input="clearErrorMessages('password_confirmation')"
                        @keyup.enter="$emit('triggerSubmit')"
                    />
                </v-col>
            </v-row>
        </v-row>

        <v-row v-if="value.login_type == $constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL') && !initialOptIn">
            <v-col cols="12" class="py-0">
                <div class="d-flex inline align-center">
                    <v-checkbox
                        v-model="value.opt_in"
                        hide-details
                        class="ma-0 mb-1"
                        :error-messages="getOptInErrorMessages()"
                        @input="clearErrorMessages('opt_in')"
                    />
                    <i18n path="forms.CompleteForm.opt_in.label" tag="p" class="ma-0 caption text-justify">
                        <template #terms>
                            <a :href="termsUrl" target="_blank" class="opt_in" :class="isLightTheme ? 'blue--text': 'primary--text'">{{ $t('forms.CompleteForm.opt_in.terms') }}</a>
                        </template>
                        <template #privacy>
                            <a :href="privacyPolicyUrl" target="_blank" class="opt_in" :class="isLightTheme ? 'blue--text': 'primary--text'">{{ $t('forms.CompleteForm.opt_in.privacy_policy') }}</a>
                        </template>
                    </i18n>
                </div>
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>
        
        <AlertModal ref="alert"/>
    </v-form>
</template>

<script>
import RateColor         from '@/shared/mixins/RateColor'
import CompanySettings   from '@/models/CompanySettings'
import SimpleCustomer    from '@/models/SimpleCustomer'
import FormMixin         from '@/mixins/FormMixin'
import AlertModal        from '@/components/modals/AlertModal'
import GenderSelectInput from '@/components/inputs/GenderSelectInput'
import { mapGetters }    from 'vuex' 

export default {
    name: 'CompleteForm',
    mixins: [ FormMixin(SimpleCustomer, 'name_field'), RateColor ],
    components: {
        AlertModal,
        GenderSelectInput
    },
    data: vm => {
        return {
            initialOptIn: vm.value.opt_in,
            company     : new CompanySettings(),
        }
    },
    props: {
        useCNPJ: Boolean,
    },
    methods: {
        // Retorna as validações cujos nomes foram informados em validations. Retorna true somente se nenhuma indicar erros
        runValidations(validations) {
            let ret = true
            validations.forEach(validation => {
                if (typeof this[validation] !== 'function')
                    return

                let result = this[validation]()
                if (result && result.length)
                    ret = false
            })

            return ret
        },
        // Opt required
        getOptInErrorMessages() {
            if (!this.value._isTouched)
                return null

            if (this.value.login_type == this.$constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL') && !this.initialOptIn && !this.value.opt_in) {
                this.isFormValidLocal = false
                return [this.$t('forms.CompleteForm.opt_in.required_error')]
            }
            
            this.isFormValidLocal = true
            return null
        },
        // Requer password
        getPasswordErrorMessages() {
            if (!this.value._isTouched)
                return null

            if (this.value.require_password && !this.value.password) {
                this.isFormValidLocal = false
                return [this.$t('forms.CompleteForm.password.required')].concat(this.getErrorMessages('password'))
            }

            return this.getErrorMessages('password')
        },
        // Confere se a senha do input é igual a sua confimação e, caso não seja, atribuí este erro ao array de erros
        getConfirmationPasswordErrorMessages() {
            let passwordField = this.$refs.password_field
            let passwordConfirmationField = this.$refs.password_confirmation_field

            let mismatchError = []
            if ( passwordField && passwordConfirmationField &&
                 passwordField.localValue != passwordConfirmationField.localValue ) {

                return [this.$t('forms.CompleteForm.password_confirmation.mismatch')].concat(this.getErrorMessages('password_confirmation'))
            }
            
            return this.getErrorMessages('password_confirmation')
        },
        // Caso o usuário tenha login por email, impede que o mesmo deixa de informar um email
        getEmailErrorMessages() {
            let emailField = this.$refs.email_field

            if (this.value.login_type == this.$constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL') && emailField && !emailField.localValue)
                return [this.$t('forms.CompleteForm.email.required')].concat(this.getErrorMessages('email'))

            return this.getErrorMessages('email')
        },

        async presubmit() {
            this.value.touch()

            if (!this.runValidations([
                    'getOptInErrorMessages',
                    'getPasswordErrorMessages',
                    'getConfirmationPasswordErrorMessages',
                    'getEmailErrorMessages',
                ])) {
                return false
            }

            // [TODO][FID-648] Corrigir componente InputDate para retornar valores SEM MÁSCARA
            // [TODO-L][FID-114] internacionalizar
            if (this.value.birthdate) {
                let valBirthdateRes = this.companySettings.applyAgeRestrictionRuleTo(this.$moment(this.value.birthdate, 'DD/MM/YYYY'))
                if (valBirthdateRes !== true) {
                    if (valBirthdateRes == CompanySettings.AGE_RESTRICTION_FROM)
                        await this.$refs.alert.showError(this.$t('forms.CompleteForm.errors.age_restriction_from', { from: this.companySettings.allow_from_age }))
                    else if (valBirthdateRes == CompanySettings.AGE_RESTRICTION_TO)
                        await this.$refs.alert.showError(this.$t('forms.CompleteForm.errors.age_restriction_to',   { to: this.companySettings.allow_to_age }))

                    return false
                }
            }

            // Se não teve problema, pode continuar com a submissão
            return true
        },
        shouldShowField(field) {
            return this.$lodash.get(this.companySettings, `customFields.Customer.${field}.active`) === false ? false : true
        },
        colSizeNameField() {
            let fieldsNumberInUseIsPair = this.company.fieldsNumberInUseIsPair(
                'Customer',
                this.companySettings.customFields,
                this.requestedFields,
            )
            // retorna tamanho da da coluna a ser utilizada pelo vuetify
            return fieldsNumberInUseIsPair ? 6 : 12
        },
    },
    computed: {
        ...mapGetters({
            json           : 'company/json',
            companySettings: 'company/settings',
        }),

        computedUseCNPJ: {
            get() {
                return this.useCNPJ
            },
            set(value) {
                this.$emit('document-changed', value)
            },
        },
        termsUrl() {
            return this.$lodash.get(this.json, 'legal.terms_url', undefined)
        },
        privacyPolicyUrl() {
            return this.$lodash.get(this.json, 'legal.privacy_policy_url', undefined)
        },
        isEmailLogin() {
            return this.value.login_type == this.$constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL')
        },
        requestedFields() {
            return ["name", "document", "email", "birthdate"]
        }
    },
}
</script>

<style scoped>
.opt_in {
    text-decoration: none;
}
</style>