<template>
    <div class="fill-height" :style="computedBgStyle">
        <div :style="computedLyStyle"></div>
        <v-container v-if="!loading" d-flex fill-height justify-center align-center>
            <AlertModal ref="alert" />

            <div class="register-card-wrapper">
                <!-- <div>
                    <v-img :src="logo_url"></v-img>
                </div> -->
                <v-card width="100%" class="py-2 px-4">
                    <v-card-title>
                        <div class="subtitle-1 mb-2 font-weight-bold primary--text">{{ $t('views.CompleteProfileView.title') }}</div>
                    </v-card-title>
                 
                    <v-card-text>
                        <CompleteForm
                            v-if="!loading"
                            :useCNPJ="useCNPJ"
                            ref="form"
                            v-model="user"
                            :isFormValid.sync="isFormValid"
                            @submit="submit"
                            @triggerSubmit="triggerSubmit"
                            @document-changed="changeDocument"
                            :loading="loading"
                        />
                        <div v-else class="text-center mt-5">
                            {{ $t('globals.loading') }}
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" color="primary"
                            depressed @click="triggerSubmit"
                            :disabled="!isFormValid" class="px-5"
                            :loading="loading" height="42px"
                        >
                            {{ $t('actions.update') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>

            <div id="recaptcha"></div>

        </v-container>
    </div>
</template>

<script>
import CompleteForm         from '@/components/forms/CompleteForm'
import AlertModal           from '@/components/modals/AlertModal'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import HasBackgroundMixin   from '@/mixins/HasBackgroundMixin'
import NextOrRedirect       from '@/shared/mixins/NextOrRedirect'
import SimpleCustomer       from '@/models/SimpleCustomer'
import { mapGetters }       from 'vuex'

export default {
    name: 'CompleteProfileView',
    mixins: [ HasBackgroundMixin, HasErrorHandlerMixin, NextOrRedirect ],
    data: vm => ({
        isFormValid: true,
        user       : new SimpleCustomer,
        loading    : false,
        useCNPJ    : false,
    }),
    components: {
        CompleteForm,
        AlertModal,
    },
    async created() {
        this.loading = true
        await this.fetchUser()
        this.user.use_cnpj = this.user.determineUseCNPJ()
        this.user.formatToFront()
        this.loading = false
    },
    methods: {
        triggerSubmit() {
            this.$refs.form.submit()
        },
        async submit() {
            this.loading = true
            
            this.user.formatToBack()

            let errType
            let response = await this.user.update()
                .catch(err => {
                    this.user.formatToFront()
                    this.error = true
                    this.loading = false

                    errType = err.response.data.type
                })

            this.loading = false

            if (!response || !response.data || response.status != 200 || errType) {
                const definedErrors = [
                    'update_error',
                    'used_email',
                    'used_phone',
                    'used_document',
                    'password_error',
                ]
                errType = definedErrors.includes(errType) ? errType : 'default'
                await this.$refs.alert.showError(this.$t(`views.CompleteProfileView.alerts.${errType}`))
                return
            } 

            await this.$store.dispatch('auth/setToken', response.data.token)
                .catch(this.errorHandler)

            await this.$store.dispatch('auth/setUser', new SimpleCustomer(response.data.data))
                .catch(this.errorHandler)

            this.user = this.authUser.clone()

            this.nextOrRedirect({ name: 'home' })
        },
        async fetchUser() {
            if (!this.authUser) {
                await this.$store.dispatch('auth/fetchCurrentCustomer')
                    .catch(this.errorHandler)
            }

            // Ocorreu algum erro
            if (!this.authUser)
                return

            // Se o cliente já tem o perfil completo, vai para o redirect ou home
            if (this.authUser.hasCompleteBaseInformations()) {
                this.nextOrRedirect({ name: 'home' })
                return
            }

            this.user    = this.authUser.clone()
            this.useCNPJ = false
        },
        changeDocument(value) {
            this.useCNPJ = value
            this.user.use_cnpj = this.useCNPJ
        },
    },
    computed: {
        ...mapGetters({
            logo_url: 'company/logo_url',
            authUser: 'auth/user',
        }),
    },
}
</script>

<style lang="scss" scoped>
.register-card-wrapper {
    width: 800px;
}
</style>