export default {
    methods: {
        /**
         * Segue para a rota passada como parâmetro caso não tenha redirect na query ou nos params da rota atual.
         * Primeiro considera query, params, componente e por fim o argumento.
         *
         * @param {object} route rota para seguir caso não tenha redirect
         * @returns
         */
        nextOrRedirect(route = { name: 'home' }) {
            let queryRedirect     = this.$lodash.get(this.$route, 'query.redirect')
            let paramsRedirect    = this.$lodash.get(this.$route, 'params.redirect')
            let componentRedirect = this.$lodash.get(this, 'redirect')

            this.$router.push(queryRedirect || paramsRedirect || componentRedirect || route)
        },
    },
}
