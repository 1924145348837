var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      attrs: { "lazy-validation": "" },
      on: { submit: _vm.submit },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: _vm.colSizeNameField() }
            },
            [
              _c("cp-text-field", {
                ref: "name_field",
                attrs: {
                  label: _vm.$t("forms.CompleteForm.name.label"),
                  placeholder: _vm.$t("forms.CompleteForm.name.placeholder"),
                  required: "",
                  type: "text",
                  "error-messages": _vm.getErrorMessages("name")
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("name")
                  }
                },
                model: {
                  value: _vm.value.name,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "name", $$v)
                  },
                  expression: "value.name"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", xs: "12", sm: "6" } },
            [
              _c("cp-document-field", {
                ref: "document_field",
                attrs: {
                  label: _vm.$t(
                    "forms.CompleteForm.document.label." +
                      (_vm.computedUseCNPJ ? "cnpj" : "cpf")
                  ),
                  placeholder: _vm.$t(
                    "forms.CompleteForm.document.placeholder." +
                      (_vm.computedUseCNPJ ? "cnpj" : "cpf")
                  ),
                  required: "",
                  type: "text",
                  useCNPJ: _vm.computedUseCNPJ,
                  "error-messages": _vm.getErrorMessages("document")
                },
                on: {
                  "update:useCNPJ": function($event) {
                    _vm.computedUseCNPJ = $event
                  },
                  "update:use-c-n-p-j": function($event) {
                    _vm.computedUseCNPJ = $event
                  },
                  input: function($event) {
                    return _vm.clearErrorMessages("document")
                  }
                },
                model: {
                  value: _vm.value.document,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "document", $$v)
                  },
                  expression: "value.document"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", xs: "12", sm: "6" } },
            [
              _c("cp-text-field", {
                ref: "email_field",
                attrs: {
                  label: _vm.$t("forms.CompleteForm.email.label"),
                  placeholder: _vm.$t("forms.CompleteForm.email.placeholder"),
                  hint: !_vm.isEmailLogin
                    ? _vm.$t("forms.CompleteForm.email.hint")
                    : "",
                  "persistent-hint": "",
                  required: "",
                  type: "text",
                  "error-messages": _vm.getEmailErrorMessages(),
                  disabled: !_vm.isEmailLogin
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("email")
                  }
                },
                model: {
                  value: _vm.value.email,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "email", $$v)
                  },
                  expression: "value.email"
                }
              })
            ],
            1
          ),
          _vm.shouldShowField("phone")
            ? _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", xs: "12", sm: 6 } },
                [
                  _c("cp-phone-field", {
                    ref: "phone_field",
                    attrs: {
                      label: _vm.$t("forms.CompleteForm.phone.label"),
                      placeholder: _vm.$t(
                        "forms.CompleteForm.phone.placeholder"
                      ),
                      required: "",
                      type: "text",
                      "error-messages": _vm.getErrorMessages("phone")
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearErrorMessages("phone")
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.$emit("triggerSubmit")
                      }
                    },
                    model: {
                      value: _vm.value.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "phone", $$v)
                      },
                      expression: "value.phone"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", xs: "12", sm: "6" } },
            [
              _c("cp-date-field", {
                ref: "birthdate_field",
                attrs: {
                  label: _vm.$t("forms.CompleteForm.birthdate.label"),
                  placeholder: _vm.$t(
                    "forms.CompleteForm.birthdate.placeholder"
                  ),
                  required: "",
                  type: "text",
                  "error-messages": _vm.getErrorMessages("birthdate"),
                  reactive: "",
                  birthday: "",
                  outputFormat: "DD-MM-YYYY"
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("birthdate")
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.submit($event)
                  }
                },
                model: {
                  value: _vm.value.birthdate,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "birthdate", $$v)
                  },
                  expression: "value.birthdate"
                }
              })
            ],
            1
          ),
          _vm.shouldShowField("sex")
            ? _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", xs: "12", sm: 6 } },
                [
                  _c("GenderSelectInput", {
                    ref: "sex_field",
                    attrs: {
                      label: _vm.$t("forms.CompleteForm.gender.label"),
                      placeholder: _vm.$t(
                        "forms.CompleteForm.gender.placeholder"
                      ),
                      hint: _vm.$t("forms.CompleteForm.gender.hint"),
                      "error-messages": _vm.getErrorMessages("sex_field")
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearErrorMessages("sex_field")
                      }
                    },
                    model: {
                      value: _vm.value.sex,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "sex", $$v)
                      },
                      expression: "value.sex"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.value.require_password
            ? _c(
                "v-row",
                { staticClass: "py-0 mx-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xs: "12", sm: "6" }
                    },
                    [
                      _c("cp-password-field", {
                        ref: "password_field",
                        attrs: {
                          label: _vm.$t(
                            "forms.CompleteForm.password.label_required"
                          ),
                          hint: _vm.$t("forms.CompleteForm.password.hint"),
                          placeholder: _vm.$t(
                            "forms.CompleteForm.password.placeholder"
                          ),
                          type: "text",
                          "error-messages": _vm.getPasswordErrorMessages()
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("password")
                          }
                        },
                        model: {
                          value: _vm.value.password,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "password", $$v)
                          },
                          expression: "value.password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xs: "12", sm: "6" }
                    },
                    [
                      _c("cp-password-field", {
                        ref: "password_confirmation_field",
                        attrs: {
                          label: _vm.$t(
                            "forms.CompleteForm.password_confirmation.label_required"
                          ),
                          hint: _vm.$t(
                            "forms.CompleteForm.password_confirmation.hint"
                          ),
                          placeholder: _vm.$t(
                            "forms.CompleteForm.password_confirmation.placeholder"
                          ),
                          type: "text",
                          "error-messages": _vm.getConfirmationPasswordErrorMessages()
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages(
                              "password_confirmation"
                            )
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.$emit("triggerSubmit")
                          }
                        },
                        model: {
                          value: _vm.value.password_confirmation,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "password_confirmation", $$v)
                          },
                          expression: "value.password_confirmation"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.value.login_type ==
        _vm.$constants.getConstant("CUSTOMER_LOGIN_TYPE_EMAIL") &&
      !_vm.initialOptIn
        ? _c(
            "v-row",
            [
              _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex inline align-center" },
                  [
                    _c("v-checkbox", {
                      staticClass: "ma-0 mb-1",
                      attrs: {
                        "hide-details": "",
                        "error-messages": _vm.getOptInErrorMessages()
                      },
                      on: {
                        input: function($event) {
                          return _vm.clearErrorMessages("opt_in")
                        }
                      },
                      model: {
                        value: _vm.value.opt_in,
                        callback: function($$v) {
                          _vm.$set(_vm.value, "opt_in", $$v)
                        },
                        expression: "value.opt_in"
                      }
                    }),
                    _c("i18n", {
                      staticClass: "ma-0 caption text-justify",
                      attrs: {
                        path: "forms.CompleteForm.opt_in.label",
                        tag: "p"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "terms",
                            fn: function() {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass: "opt_in",
                                    class: _vm.isLightTheme
                                      ? "blue--text"
                                      : "primary--text",
                                    attrs: {
                                      href: _vm.termsUrl,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "forms.CompleteForm.opt_in.terms"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "privacy",
                            fn: function() {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass: "opt_in",
                                    class: _vm.isLightTheme
                                      ? "blue--text"
                                      : "primary--text",
                                    attrs: {
                                      href: _vm.privacyPolicyUrl,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "forms.CompleteForm.opt_in.privacy_policy"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        41380461
                      )
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._t("default"),
      _vm._t("submit"),
      _c("AlertModal", { ref: "alert" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }