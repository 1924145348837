var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height", style: _vm.computedBgStyle },
    [
      _c("div", { style: _vm.computedLyStyle }),
      !_vm.loading
        ? _c(
            "v-container",
            {
              attrs: {
                "d-flex": "",
                "fill-height": "",
                "justify-center": "",
                "align-center": ""
              }
            },
            [
              _c("AlertModal", { ref: "alert" }),
              _c(
                "div",
                { staticClass: "register-card-wrapper" },
                [
                  _c(
                    "v-card",
                    { staticClass: "py-2 px-4", attrs: { width: "100%" } },
                    [
                      _c("v-card-title", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "subtitle-1 mb-2 font-weight-bold primary--text"
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("views.CompleteProfileView.title"))
                            )
                          ]
                        )
                      ]),
                      _c(
                        "v-card-text",
                        [
                          !_vm.loading
                            ? _c("CompleteForm", {
                                ref: "form",
                                attrs: {
                                  useCNPJ: _vm.useCNPJ,
                                  isFormValid: _vm.isFormValid,
                                  loading: _vm.loading
                                },
                                on: {
                                  "update:isFormValid": function($event) {
                                    _vm.isFormValid = $event
                                  },
                                  "update:is-form-valid": function($event) {
                                    _vm.isFormValid = $event
                                  },
                                  submit: _vm.submit,
                                  triggerSubmit: _vm.triggerSubmit,
                                  "document-changed": _vm.changeDocument
                                },
                                model: {
                                  value: _vm.user,
                                  callback: function($$v) {
                                    _vm.user = $$v
                                  },
                                  expression: "user"
                                }
                              })
                            : _c("div", { staticClass: "text-center mt-5" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("globals.loading")) + " "
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-5",
                              attrs: {
                                type: "submit",
                                color: "primary",
                                depressed: "",
                                disabled: !_vm.isFormValid,
                                loading: _vm.loading,
                                height: "42px"
                              },
                              on: { click: _vm.triggerSubmit }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("actions.update")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { attrs: { id: "recaptcha" } })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }